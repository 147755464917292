.table_section {
  margin: 20px;

  background-color: white;
}

thead {
  border: 1px solid black;
  text-align: center;
}

tr {
  border: 1px solid black;
  text-align: center;
}

.table_heading {
  
  text-align: center;
}
