/* alert */

.modal {
  display: none;
  position: fixed;
  z-index: 99999900999;
  left: 0;
  top: -100px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #816a47;
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
  background-color: #000000df;
  color: #816a47;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.close {
  width: 25px;
  font-size: 28px;
}

.close:hover,
.close:focus {
  color: rgb(248, 56, 56);
  text-decoration: none;
  cursor: pointer;
}

/* form */
.form_admin {
  margin-top: 10px;
  width: 90%;
  margin: auto;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 100px;
}

.form_admin .vodka_btn {
  background: linear-gradient(210deg, #f3cc92, #342814);
  color: #f5deb3;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  letter-spacing: 2px;
  padding: 17px 61.16px 18px 62px;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
}

.admin_logo img {
  width: 311.47px;
  height: 240px;
}

.loginForm {
  width: 35%;
  padding: 50px;
}
.form-group {
  display: flex;
  margin-bottom: 3vh;
  position: relative;
}

.form-group input {
  padding: 0px 5px;
  background-color: transparent;
  font-size: 18px;
  color: #000;
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
}

.form-group input::placeholder {
  color: #000;
  margin: 0;
  font-size: 18px;
  transition: 0.5s;
}

.form-button {
  text-align: center;
  margin-top: 80px;
}

.form-button .admin_btn {
  border-color: black;
  background-color: transparent;
  color: black;
  padding: 10px 35px;
  font-weight: bold;
  border: 1px solid;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: 0.6s;
}

.form-button .admin_btn:hover {
  background-color: black;
  color: #816a47;
}

.forgot_password {
  display: block;
  margin-top: 40px;
  color: #000;
  text-align: center;
  cursor: pointer;
}

.forgot_password:hover {
  text-decoration: underline;
}
