.ContactFooter {
  padding: 40px 0 30px 0;
  color: white;
.ContactFooterLeft, .ContactFooterRight{
  text-align: center;
  p{
    margin: 0;
  }
  h6{
    margin: 0;
  }
}
}

.ContactFooterCardTitle{
  color: rgba(199, 168, 113, 1);
  
}