

@media screen and (max-width: 767px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

    .navbar__logo {
      img {
        width: 69px;
        height: 53px;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .navbar {
    display: none;
  }
}
.offcanvas {
  width: 100%;
}
.Offcanvas_body {
  background-color: #010101 !important;
  display: flex;
  justify-content: center;
  ul {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  li {
    color: white;
    text-decoration: none;
    list-style: none;
    padding-top: 20px;

    a {
      color: white;
      text-decoration: none;
      font-size: 2rem;
    }
  }
}
.Offcanvas_header {
  background-color: #010101 !important;
  color: #bea741;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icongap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.button_offanvas {
  display: flex;
  justify-content: center;
  align-items: center;
}
.offcanvas_title {
  margin-left: 20px;
  padding: 20px 0;
  font-size: 2rem;
  font-weight: 800;
}
.button_offcanvas {
  height: 25px;
}

.langbtnheader {
  margin: 0 5px;
  background-color: #131313;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  &:hover {
    color: #bea741;
    transition: all 0.3s ease-in-out;
  }
}
