.section {
  padding: 20px;
}


.sectionMain__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.brandtext {
  color: rgba(138, 101, 48, 1);
}

.sectionMain {
  text-align: center;
  width: 60%;
  color: white;
  margin: 0 auto;
}

.sectionMain__title h1 {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 700;
  color: rgba(199, 168, 113, 1);
}

.sectionMain__text {
  background: linear-gradient(90deg, #836b48 0%, #342814 100%);
  padding: 10px;
  color: white;
}

.SectionTop {
  background-color: #0a0a0a;
  color: white;
  padding: 20px;
}

.SectionTopLeft img {
  width: 100%;
}

.SectionTopRight {
  text-align: center;
  padding-top: 10px;
}
