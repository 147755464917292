.header {
  font-family: cursive !important;
  z-index: 11;
  .headerLogo {
    img {
      width: 69px;
      height: 53px;
    }
  }
  background-color: #131313;
  position: fixed;
  width: 100%;
  top: 0;
  font-family: cursive !important;
  .active {
    color: rgba(138, 101, 48, 1);
    text-decoration: none;
  }

  .item {
    color: white;
    text-decoration: none;

    &:hover {
      color: gold;
      transition: all 0.3s ease-in-out;
    }
  }
}

.headerCenter {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 1rem 0;
  text-decoration: none;

  font-weight: 500;
  font-style: normal;
  li {
    color: white;
    list-style: none;
    font-size: 26px;

    &:hover {
      color: #bea741;
      transition: all 0.3s ease-in-out;
    }
  }
}

.headerRight {
  cursor: default;
  margin: 1rem 0;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ul {
    display: flex;
    gap: 20px;
    margin: 0;
    li {
      list-style: none;
      a {
        color: white;
        text-decoration: none;
        &:hover {
          color: #bea741;
          transition: all 0.3s ease-in-out;
        }
      }
      &:hover {
        color: #bea741;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    display: none;
  }
}

.langbtnheader {
  background-color: #131313;

  color: white;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  &:hover {
    color: #bea741;
    transition: all 0.3s ease-in-out;
  }
}
