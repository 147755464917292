#customAlertdelete .modal-content {
  margin: 10% auto 200px auto;
  padding: 20px;
  border: 1px solid #816a47;
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
  background-color: #fd5c63;
  color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

#customAlertdelete .modal-content .close:hover {
  color: rgba(0, 0, 0, 0.795);
}

.product_tbody {
  margin-bottom: 20px;
}

.form-group-custom {
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.product_table_btn{
 text-align: center;
  
}
