

.dashboardSidebar{
  min-height: 100vh;
  background-color: #D1E9F0;

}


.dashboard_body_content{
  min-height: 100vh;
}

.dashboardSidebar .admin_logo {
  text-align: center;
  padding: 10px 0 0 0 ;
  background: linear-gradient(210deg, #f3cc92, #342814);
}

.dashboardSidebar .admin_logo img {
  width: 100px;
  height: 85px;
}

.dashboardSidebar a {
  color: #0F282E;
  font-size: 28px;
  font-weight: 700;
  text-decoration: none;
  transition: .5s;
}

.sidebar_topic {
  padding-top: 50px;
  background-color: #D1E9F0;
  height: 100vh;
  position: fixed;
  width: 16.6%;

}

.sidebar_product,
.sidebar_category {
  width: 100%;
  text-align: center;
  transition: 0.5s;
  cursor: pointer;
}

.sidebar_category:hover {
  background-color: #0F282E;
  a{
    color: #D1E9F0;
    
  }
}

.sidebar_product:hover {
  background-color: #0F282E;
  a{
    color: #D1E9F0;
  }
  
}
