.marquee {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: #131313;
    margin-top: 10px;
    padding-top: 10px;
    color: white;
  }
  
  .marquee p {
    display: inline-block;
    font-size: 22px;
    color: white ;
  }

  .marquee a{
    font-size: 22px;
    color: yellow;
  }
  

  