.modal-dialog{
    top: 100px !important;
}

.modal-body{
    margin: 30px 0;
}

.btn-close{
    background-color: #A3D5E1;
}

td{
    text-align: center;
}