.Footer {
  background-color: #131313;
  color: #fff;
  padding: 40px 0;

  text-align: center;

  .brandText {
    color: #bea741 !important;
    margin: 0;
  }
  h1{
    padding: 5px 0 20px 0;
  }
}


.img{
  width: 136px;
  height: 160px;
  object-fit: cover;
}